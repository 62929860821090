<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * FAQs component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Terms and Conditions",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Terms and Conditions",
      items: [
        {
          text: "Londex",
        },
        {
          text: "Terms and Conditions",
          active: true,
        },
      ],
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-5">
              <div class="col-xl-3 col-sm-5 mx-auto">
                <div>
                  <img
                    src="@/assets/images/faqs-img.png"
                    alt=""
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>

              <div class="col-xl-8">
                <div id="faqs-accordion" class="custom-accordion mt-5 mt-xl-0">
                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.general-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="general-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">General</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="general-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>
                            Londex Website & Dashboard Games Terms and Conditions of Use of Services of the Company<br/>
Updated: 15.09.2021<br/></p>
<p>
Definitions
</p>
<p>*     Londex is referred to as 'we' or 'us' or “our”</p>
<p>*     The Player is referred to as "you" or 'the Player'</p>
<p>*     ‘Games’ means Casino and other games as may from time to time become available on the Website or Dashboard.</p>
<p>*     'The Website' means http://gaming.londonfork.baby through desktop, mobile or other devices utilised by the Player</p>
<p>*                  'The Dashbaord’ means http://gaming.londonfork.baby through desktop, mobile or other devices utilised by the Player</p>
<p>*     'Cryptocurrency' and 'Crypto' should further be read as ‘BNB, altcoins, and/or (where applicable) other supported cryptocurrencies'</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.faqs-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="faqs-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Terms and Conditions</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="faqs-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>1.1. These terms and conditions (“Terms and Conditions”) apply to the usage of games accessible through http://gaming.londonfork.baby</p>
                          <p>1.2. http://gaming.londonfork.baby owned and operated by the Londex token.</p>
                          <p>1.3. These Terms and Conditions come into force as soon as you connect your wallet. By using any part of the Website following connecting your wallet, you agree to these Terms and Conditions applying to the use of the Website and Games.</p>
                          <p>1.4. You must read these Terms and Conditions carefully in their entirety before connecting your wallet. If you do not agree with any provision of these Terms and Conditions, you must not continue to use the Website and Games.</p>
                          <p>1.5. We are entitled to make amendments to these Terms and Conditions at any time and without advanced notice. Your continued use of the http://gaming.londonfork.baby Website or Dashboard after any such amendment to the Terms and Conditions will be deemed as your acceptance and agreement to be bound by such amendments, updates and/or modifications.</p>
                          <p>1.6. These Terms and Conditions may be published in several languages for informational purposes and ease of access by players. The English version is the only legal basis of the relationship between you and us and in the case of any discrepancy with respect to a translation of any kind, the English version of these Terms and Conditions shall prevail.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.faqs-privacy-policy-collapse
                      class="text-dark collapsed"
                      data-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="faqs-privacy-policy-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-shield-check"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Binding Declarations</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="faqs-privacy-policy-collapse"
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>2.1. By agreeing to be bound by these Terms and Conditions, you also agree to be bound by the http://gaming.londonfork.baby Rules and Privacy Policy that are hereby incorporated by reference into these Terms and Conditions. In the event of any inconsistency, these Terms and Conditions will prevail. You hereby represent and warrant that:</p>
                          <p>2.1.1. You are over (a) 18 and (b) such other legal age or age of majority as determined by any laws which are applicable to you, whichever age is greater;</p>
                          <p>2.1.2. You have full capacity to enter into a legally binding agreement with us and you are not restricted by any form of limited legal capacity;</p>
                          <p>2.1.3. You participate in the Games strictly in your personal and non-professional capacity; and participate for recreational and entertainment purposes only;</p>
                          <p>2.1.4. You participate in the Games on your own behalf and not on the behalf of any other person;</p>
                          <p>2.1.5. All information that you provide to us during the term of validity of this agreement is true, complete, correct, and that you shall immediately notify us of any change of such information;</p>
                          <p>2.1.6. You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from us;</p>
                          <p>2.1.7. You understand that by using our services you take the risk of losing money deposited into your wallet and accept that you are fully and solely responsible for any such loss;</p>
                          <p>2.1.8. You are permitted in the jurisdiction in which you are located to use online gambling services;</p>
                          <p>2.1.9. You will not use our services while located in any jurisdiction that prohibits the placing and/or accepting of bets online (incl. denominated in cryptocurrency), and/or playing casino and/or live games including for and/or with crypto;</p>
                          <p>2.1.10. In relation to deposits and withdrawals of funds into and from your wallet, you shall only use crypto (BNB) that is valid and lawfully belongs to you;</p>
                          <p>2.1.11. You accept and acknowledge that the value of cryptocurrency can change dramatically depending on the market value;</p>
                          <p>2.1.12. The computer software, the computer graphics, the Websites and the user interface that we make available to you is owned by Londex or its associates and is protected by copyright laws where necessary.</p>
                          <p>2.1.13. You understand that crypto is not considered a legal currency or tender and as such on the Website they are treated as virtual funds with no intrinsic value.</p>
                          <p>2.1.14. You affirm that you are not a developer or freelance developer of Londex.</p>
                          <p>2.1.15. You are not diagnosed or classified as a compulsive or problem gambler. We are not accountable if such problem gambling arises whilst using our services, but will endeavour to inform of relevant assistance available.</p>
                          <p>2.1.16. You are not politically exposed person or a family member of a politically exposed person;</p>
                          <p>2.1.17. You have only one wallet using the games at any one time.</p>
                          <p>2.1.18. You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automation, gameplay and screen capture techniques. These steps may include, but are not limited to, examination of Players device wallet activity, detection of geo-location and IP masking, transactions and blockchain analysis;</p>
                          <p>2.1.19. You accept our right to terminate and/or change any games or events being offered on the Website or Dashboard, and to refuse and/or limit bets.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.faqs-pricing-plans-collapse
                      class="text-dark collapsed"
                      data-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="faqs-pricing-plans-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-pricetag-alt"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Your Wallet</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="faqs-pricing-plans-collapse"
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>3.1. In order for you to be able to place bets on our website, you must first personally connect your wallet.</p>
                          <p>3.2. You will inform us as soon as you become aware of any errors with respect to your wallet or any calculations with respect to any bet you have placed. We reserve the right to declare null and void any bets that are subject to such an error.</p>
                          <p>3.3. We have the right to carry out “KYC” (Know Your Customer) verification procedures either by us or a 3rd party validator, and access to your winnings may be blocked or invalidated if we determine that you have acted in a malicious manner.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.Deposits-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="Deposits-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Deposits</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="Deposits-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>4.1. You may participate in any Game only if you have sufficient funds in your wallet for such participation. We shall not give you any credit whatsoever for participation in any Game.</p>
                          <p>4.2. The minimum deposit amount is 0.1 BNB.</p>
                          <p>4.3. We reserve the right to use additional procedures and means to verify your identity when processing extremely large ticket purchases.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.privacy-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="privacy-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Privacy Policy</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="privacy-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>5.1. You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your data in order to allow you access and use of the Website or Dashboard and in order to allow you to participate in the Games.</p> 
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.rules-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="rules-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">General Betting Rules</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="rules-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                            <p>6.1. A bet can only be placed the wallet holder.</p>
                            <p>6.2. A bet can only be placed over the internet and when connected to the website or dashboard.</p>
                            <p>6.3. You can only place a bet if you have sufficient funds in your wallet.</p>
                            <p>6.4. The bet, once concluded, will be governed by the version of the Terms and Conditions valid and available on the website at the time of the bet being accepted.</p>
                            <p>6.5. Any payout of a winning bet is credited to your wallet, consisting of the stake multiplied by the odds at which the bet determined.</p>
                            <p>6.6. A bet, which has been placed and accepted, cannot be amended, withdrawn or cancelled by you.</p>
                            <p>6.8. The list of all the bets, their status and details are available to you on the Dashboard.</p>
                            <p>6.9. When you place a bet you acknowledge that you have read and understood in full all of these Terms and Conditions regarding the bet as stated on the Website.</p>
                            <p>6.10. Our AI manages your bet, calculates the available tickets, the betting result as well as the amount of winnings. Unless proven otherwise, these amounts are considered as final and are deemed to be accurate.</p>
                            <p>6.11. You are fully responsible for the bets placed.</p>
                            <p>6.12. Winnings will be paid into your wallet after the final result is confirmed.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.Miscarried-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="Miscarried-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Miscarried and aborted games</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="Miscarried-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>7.1. The Company is not liable for any downtime, server disruptions, lagging, or any technical or political disturbance to the game play.</p>  
                          <p>7.2. Londex shall accept no liability for any damages or losses which are deemed or alleged to have arisen out of or in connection with the website, dashboard or its content; including without limitation, delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person's misuse of the site or its content or any errors or omissions in content.</p>  
                          <p>7.3. In the event of a Game system malfunction all wagers are void.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.Limitation-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="Limitation-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Limitation of Liability</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="Limitation-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>8.1. You enter the Website or Dashboard and participate in the Games at your own risk. The Websites, Dashboard and the Games are provided without any warranty whatsoever, whether expressed or implied.</p>
                          <p>8.2. Without prejudice to the generality of the preceding provision, we, our developers, freelance developers, partners, service providers.</p>
                          <p>8.2.1. Do not warrant that the software, Games and the Websites or Dashboard are fit for their purpose;</p>
                          <p>8.2.2. Do not warrant that the software, Games and the Websites or Dashboard are free from errors;</p>
                          <p>8.2.3. Do not warrant that the software, Games and the Websites or Dashboard will be accessible without interruptions</p>
                          <p>8.2.4. Shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Websites, Dashboard or your participation in the Games.</p>
                          <p>8.3. You understand and acknowledge that, if there is a malfunction in a Game or its interoperability, any bets made during such a malfunction shall be void.</p>
                          <p>8.4. You hereby agree to fully indemnify and hold harmless us, our developers, freelance developers, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in relation to your use of the Website, Dashboard or participation in the Games.</p>
                          <p>8.5. To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty or otherwise), will not exceed $1.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.Breaches-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="Breaches-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Breaches, Penalties and Termination</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="Breaches-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>9.1. If you breach any provision of these Terms and Conditions or we have a reasonable ground to suspect that you have breached them, we reserve the right to blacklist your wallet, or withhold payment of your winnings and apply such funds to any damages due by you.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.Severability-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="Severability-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Severability</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="Severability-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>10.1. If any provision of these Terms and Conditions is held to be illegal or unenforceable, such provision shall be severed from these Terms and Conditions and all other provisions shall remain in force unaffected by such severance.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.Assignment-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="Assignment-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Assignment</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="Assignment-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>11.1. We reserve the right to assign or otherwise lawfully transfer this agreement. You shall not assign or otherwise transfer this agreement.</p>  
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <div class="card border shadow-none">
                    <a
                      href="javascript: void(0);"
                      v-b-toggle.Entire-gen-ques-collapse
                      class="text-dark"
                      data-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="Entire-gen-ques-collapse"
                    >
                      <div class="bg-light p-3">
                        <div class="media align-items-center">
                          <div class="me-3">
                            <div class="avatar-xs">
                              <div
                                class="avatar-title rounded-circle font-size-22"
                              >
                                <i class="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="font-size-16 mb-1">Entire Agreement</h5>
                          </div>
                          <i
                            class="mdi mdi-chevron-up accor-down-icon font-size-16"
                          ></i>
                        </div>
                      </div>
                    </a>

                    <b-collapse
                      id="Entire-gen-ques-collapse"
                      visible
                      accordion="faqs-accordion"
                      data-parent="#faqs-accordion"
                    >
                      <div class="p-4">
                        <div class="row">
                          <p>12.1. These Terms and Conditions constitute the entire agreement between you and us with respect to the Websites, Dashboard and, save in the case of fraud, supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to the Websites or Dashboard.</p>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
